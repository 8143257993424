<template>
  <div class="mt-1">
    <feather-icon
      title="Remove from company"
      icon="XIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="confirmDeleteRecord"
    />
    <vs-prompt
      title="Delete Customer"
      accept-text="Delete"
      @cancel="closeDeleteDialog"
      @close="closeDeleteDialog"
      @accept="deleteCustomer"
      :active.sync="showDeleteDialog"
    >
      <div class="vx-row mt-2 mb-4">
        <div class="vx-col">         
          <span 
            >Are you sure you want to unlink customer {{this.params.data.email}}?
              </span>
          
        </div>       
        </div>      
    </vs-prompt>
  </div>
</template>

<script>
import TpNotificationMixin from "@/components/travio-pro/TpNotificationMixin.vue";
import vSelect from "vue-select";
import _ from "lodash";
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';
export default {
  name: "CellRendererActions",
  components: {
    vSelect,
  },
  mixins: [TpNotificationMixin],
  data() {
    return {
      showDeleteDialog: false,
    };
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    editRecord() {     
     this.$router.push({
        name: "company-editor-edit",
        params: {
          applicationId: this.$route.params.applicationId,
          companyId: this.params.data.id,
        },
      })
      .catch((error) => console.error(error));
    },
    displayRecord() {
      this.$router.push({
        name: "company-editor-view",
        params: {
          applicationId: this.$route.params.applicationId,
          companyId: this.params.data.id,
        },
      })
      .catch((error) => console.error(error));
    },
    manageUsers () {
      this.$router.push({
        name: 'company-linked-users',
        params: {
          applicationId: this.$route.params.applicationId,
          companyId: this.params.data.id,
        },
      })
      .catch((error) => console.error(error));
    },
    confirmDeleteRecord() {
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    deleteCustomer() {
      this.params.context.componentParent.deleteUserLink(this.params.data.customerId)
    },
  },
};
</script>
