<template>
  <div>
    <feather-icon title="View Details" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onView" />
    
  </div>
</template>

<script>
import axios from '@/axios'

export default {
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    onView () {
      this.params.context.componentParent.viewQuote(this.params.data.id)
    },

  }
}
</script>
