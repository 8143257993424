<template>
  <travio-center-container grid-width="2/3" :pageTitle="pageTitle">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex">
              <v-select placeholder="Select users to add"
                style="width:60%" 
                label="label"
                v-model="selectedUserId"
                :reduce="x=>x.code"
                :options="usersForAssignment">
                <!-- <template #option="{ code, label}">
                  <div style="margin: 0"><strong>{{ label }}</strong></div>
                  <div class="text-sm text-muted">{{ code }}</div>
                </template> -->
              </v-select>
              <vs-button :disabled="!selectedUserId" v-if="userHasPermission('apps_customers_edit')" @click="addUser" class="ml-4 sm:mb-0 mb-2">
                Add User
              </vs-button>
              <vs-button @click="manageCompanies" type="border" class="ml-4 sm:mb-0 mb-2">
                Back To Companies List
              </vs-button>
            </div>
          </div>
        </div>

        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="linkedUsers"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :context="context"
          @selection-changed="onSelectionChanged"
          @grid-size-changed="onGridSizeChanged"
        >
        </ag-grid-vue>
        <vs-pagination :total="totalPages" :max="5" v-model="currentPage" />
      </vx-card>

    </div>
  </travio-center-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererUserActions from "./cell-renderers/CellRendererUserActions.vue";
import Vue from "vue";
import { format } from 'date-fns'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  components: {
    AgGridVue,
    CellRendererUserActions,
  },
  props: {
    applicationId: { required: true },
    companyId: { required: true },
  },
  data() {
    return {      
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererUserActions,
      },
      companyName: "",
      linkedUsers: [],
      usersForAssignment: [],
      selectedUserId: null
    };
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    pageTitle () {
      return `Manage Users For Company ${this.companyName}`;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    titleOptions () {
      return this.$store.state.lookups.titleOptions
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
    
  },
  created() {

    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.$route.params.applicationId)) ) {
      this.$router.push('/error-404')
    }
    // this.linkedUsers = [
    //   { customerId: 0, email: 'johndoe@test.com', firstName: 'John Maciano The', lastName: 'Doe', isActive: true },
    //   { customerId: 0, email: 'johndo1212143234e@test.com', firstName: 'John', lastName: 'Doe', isActive: true },
    // ]
    this.loadData();
   
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs = [
      {
        headerName: "",
        width: 50,
        sortable: false,
        suppressSizeToFit: true,
        cellRendererFramework: Vue.extend(CellRendererUserActions),
      },
      { headerName: "ID", width: 90, field: "customerId", suppressSizeToFit: true, },
      {
        headerName: "Name",        
        valueGetter : this.getFullName,
        sortable: true,
        width: 150,
        suppressSizeToFit: false,
      },
      {
        headerName: "Email",
        field: "email",
        sortable: true,
        width: 150,        
        suppressSizeToFit: false,
      }
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true,
    }

    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    loadData() {
      this.$vs.loading()
      this.$http.get(`api/b2b/companies/${this.$route.params.applicationId}/${this.$route.params.companyId}/users`)
        .then(response => {
            this.companyName = response.data.companyName
            this.linkedUsers = response.data.listItemCompanyUsers
            this.usersForAssignment = response.data.listItemUsersForCompanyAssignment 
              && response.data.listItemUsersForCompanyAssignment.map(x => { 
                const fullName = x.fullName ? ` (${x.fullName})` : ''
                return {
                  code: x.customerId,
                  label: x.email + fullName
                }
              })
        })
        .catch(error => console.error(error))
        .finally(() => this.$vs.loading.close())  
    },
    activeFormatter(params){
      return params.data.isActive == 1 ? 'Active' : 'Inactive';
    },
    
    getFullName(params){
      let title = this.titleOptions.find(x => x.code === params.data.title)
      let fName = params.data.firstName == null ? "" : params.data.firstName;
      let lName = params.data.lastName == null ? "" : params.data.lastName;
      let fullNameNoTitle = fName + " " +  lName;
      if(title) {
        return title.label + " " + fullNameNoTitle
      }
      return fullNameNoTitle
    },    
    addCustomer() {
      this.$router.push({
        name: "customer-addition",
        params: { applicationId: this.$route.params.applicationId },
      });
    },
    manageCompanies () {
      this.$router.push({
        name: "company-list",
        params: { applicationId: this.$route.params.applicationId },
      });
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    onGridSizeChanged(params) {
      if (params.clientWidth > 500) {
        this.gridApi.sizeColumnsToFit();
      } else {
        this.columnDefs.forEach(x => {
          x.width = 300;
        });
        this.gridApi.setColumnDefs(this.columnDefs);
      }
    },
    addUser() {
      this.$vs.loading()
      this.$http.post(`api/b2b/companies/${this.$route.params.applicationId}/${this.$route.params.companyId}/users`, {
        userId: this.selectedUserId
      })
      .then(response => {
        this.$_notifySuccess('Company settings successfully saved');
        this.selectedUserId = null
        this.loadData();
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
    },
    deleteUserLink (userId) {
      this.$vs.loading()
      this.$http.delete(`api/b2b/companies/${this.$route.params.applicationId}/${this.$route.params.companyId}/users/${userId}`)
      .then(response => {
          this.$_notifySuccess('User successfully removed from company');
          this.selectedUserId = null
          this.loadData();
          // this.linkedUsers = this.$_.filter(this.linkedUsers, x => x.id !== userId)
          // // Reassign deopositOptionsList to trigger change detection
          // this.linkedUsers = JSON.parse(JSON.stringify(this.linkedUsers))
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())  
    },
  },
};
</script>

<style>
</style>
