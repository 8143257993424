<template>
  <travio-center-container grid-width='full'>
    <h2 style="color:#636363" class="mb-4">Quote List</h2>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-input
            class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          />
        </div>
      </div>
       <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="quotesList"
        :gridOptions="gridOptions"
        :autoGroupColumnDef="autoGroupColumnDef"
        rowSelection="single"
        :pagination="false"
        :context="context"
        :suppressPaginationPanel="true"
      >
      </ag-grid-vue>
      
      
    </vx-card>
  
  </travio-center-container>
</template>

<script>
    
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';
import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import { format } from 'date-fns'

export default {
  components: {
    AgGridVue,
    CellRendererActions
  },
  props: {
    applicationId: { required: true}
  },
  data () {
    return {
      quotesList: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererActions
      },
      context: null,
      searchQuery: ''
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    if (!this.appHasFeature('ignite')) {
      this.$router.push('/error-404')
    }

    // this.quotesList = [
    //   { id: 1, listKey: 'E045EF3E-3D78-4B2C-A411-C194FD7AE402', name: 'Top Hotels', customerId: 10, agentId: 948, lastUpdated: '2024-03-17' },
    //   { id: 2, listKey: '6E4D244A-1369-4FAE-9876-D0BD9A279946', name: 'Best Destinations', customerId: 20, agentId: null, lastUpdated: '2024-04-17' },
    //   { id: 3, listKey: 'D8CD3FAE-9ABF-4AF5-BEDC-AD57F86E58DB', name: 'Best Value Hotels', customerId: 30, agentId: 1040, lastUpdated: '2025-01-17' },
    // ]

    this.$vs.loading()

    try {
      const response = await this.$http.get(`api/quoteViewer/${this.applicationId}`)
      this.quotesList = response.data

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();

  },
  beforeMount() {

    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'id', hide: true },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActions) },
      { headerName: 'List Key', field: 'listKey', width: 320,  suppressSizeToFit: false },
      { headerName: 'Name', field: 'name', width: 320,  suppressSizeToFit: false },
      { headerName: 'Customer Id', field: 'customerId', width: 200,  suppressSizeToFit: false },
      { 
        headerName: 'Last Updated', 
        field: 'lastUpdated',
        width: 200,
        suppressSizeToFit: true,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateFormat)
      },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    viewQuote (listId) {
      this.$router.push({name: 'application-tools-quote-viewer-details', 
        params: { 
          applicationId: this.applicationId,
          listId: listId,
        }
      })
    },
  }
}
</script>

<style>

</style>